import { gql } from '@apollo/client';

export const ORGANISATION_CREATE = gql`
  mutation createOrganisation(
    $name: String!
    $gateway: String!
    $gatewayDetails: gatewayDetailsInput!
    $type: String!
  ) {
    createOrganisation(name: $name, gateway: $gateway, gatewayDetails: $gatewayDetails, type: $type) {
      id
      name
      type
      #      gateways
      status
    }
  }
`;

export const CHARGE_SUBSCRIPTION = gql`
  mutation chargeSubscription($orgId: String!, $subId: String!) {
    chargeSubscription(orgId: $orgId, subId: $subId) {
      status
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($orgId: String!, $subId: String!) {
    cancelSubscription(orgId: $orgId, subId: $subId) {
      status
    }
  }
`;

export const UPADTE_SCOPES = gql`
  mutation updateUserScopes($orgId: String, $userId: String, $scopes: [String]) {
    updateUserScopes(orgId: $orgId, userId: $userId, scopes: $scopes) {
      success
      message
    }
  }
`;

export const SEND_INVITE = gql`
  mutation sendInvite($email: String!, $orgId: String!) {
    sendInvite(email: $email, orgId: $orgId) {
      success
      message
    }
  }
`;

export const CREATE_PLAN = gql`
  mutation createPlan(
    $amount: Float!
    $currency: String
    $interval: String!
    $name: String!
    $orgId: String!
    $status: String!
    $gateway: String!
  ) {
    createPlan(
      amount: $amount
      name: $name
      orgId: $orgId
      gateway: $gateway
      currency: $currency
      interval: $interval
      status: $status
    ) {
      id
      amount
      created
      currency
      interval
      modified
      name
      orgId
      status
    }
  }
`;
export const REVOKE_AUTHTOKEN = gql`
  mutation revokeJwtToken($tokenId: String!, $orgId: String!) {
    revokeJwtToken(orgId: $orgId, requestType: revoke, tokenId: $tokenId) {
      access {
        scopes
        sourceIps
        tokenIds
      }
    }
  }
`;

export const GENERATE_AUTHTOKEN = gql`
  mutation generateJwtToken($orgId: String!) {
    generateJwtToken(orgId: $orgId, requestType: generate) {
      token
      tokenId
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($orgId: String!, $userId: String, $organisation: OrgInput) {
    updateSettings(orgId: $orgId, userId: $userId, organisation: $organisation) {
      #status
      error {
        message
        description
      }
      taxNumber
      type
      access {
        scopes
        sourceIps
        tokenIds
      }
      brand {
        color
        logoPath
      }
      gateways {
        Mock {
          secureToken
        }
        Stripe {
          secureApiKey
          publishableKey
        }
        Paystack {
          secureApiKey
        }
        Payfast {
          secureApiKey
          merchantId
          merchantKey
        }
      }
      name
      modified
      #id
      notifications {
        actions
        emails
      }
      webhook {
        url
      }
      address {
        state
        postalCode
        line2
        line1
        country
        city
      }
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation updatePlan($amount: Float!, $name: String!, $orgId: String!, $status: String!, $planId: String!) {
    updatePlan(planId: $planId, amount: $amount, name: $name, orgId: $orgId, status: $status) {
      amount
      status
      name
      interval
      modified
      id
      gateway
      currency
      orgId
    }
  }
`;
