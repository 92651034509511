import { gql } from '@apollo/client';

export const ECHO = gql`
  query echo {
    echo {
      data
    }
  }
`;
export const ORGANISATIONDATA = gql`
  query organisation($id: String!) {
    organisation(id: $id) {
      access {
        scopes
        sourceIps
        tokenIds
      }
    }
  }
`;
export const USER = gql`
  query getUser {
    getUser {
      id
      created
      email
      organisations {
        created
        id
        modified
        name
        type
        gateways
        access {
          groups
          resources
          scopes
          tokenIds
        }
        status
      }
      lName
      modified
      fName
      status
    }
  }
`;
export const SUBSCRIPTION_LIST = gql`
  query subscriptionList($orgId: String!, $limit: Int, $q: String!, $skip: Int) {
    subscriptionList(orgId: $orgId, limit: $limit, q: $q, skip: $skip) {
      total
      items {
        created
        modified
        gateway
        cron
        customer {
          id
          fName
          email
          lName
        }
        id
        orgId
        plan {
          id
          name
          amount
        }
        startDate
        status
        token
        customerId
      }
    }
  }
`;
export const CUSTOMER_LIST = gql`
  query customerList($orgId: String!, $skip: Int, $limit: Int, $q: String) {
    customerList(orgId: $orgId, skip: $skip, limit: $limit, q: $q) {
      items {
        created
        email
        fName
        gateway
        id
        lName
        modified
        orgId
        ref
        status
      }
      total
    }
  }
`;
export const PLAN_LIST = gql`
  query planList($skip: Int, $limit: Int, $orgId: String!, $q: String) {
    planList(skip: $skip, limit: $limit, orgId: $orgId, q: $q) {
      total
      items {
        amount
        status
        created
        currency
        id
        interval
        modified
        name
        orgId
      }
    }
  }
`;
export const SUBSCRIPTION_CHARGE_LIST = gql`
  query subscriptionChargeList($subId: String!, $nextToken: String, $limit: String) {
    subscriptionChargeList(subId: $subId, nextToken: $nextToken, limit: $limit) {
      nextToken
      limit
      items {
        attempt
        created
        amount
        modified
        orgId
        customerId
        date
        id
        ref
        status
        subscriptionId
      }
    }
  }
`;
export const CUSTOMER_CHARGE_LIST = gql`
  query customerChargeList($custId: String!) {
    customerChargeList(custId: $custId) {
      ref
      id
      subscriptionId
      created
      modified
      attempt
      date
      amount
      status
    }
  }
`;
export const PLAN_SUBSCRIPTION_LIST = gql`
  query planSubscriptionList($planId: String!) {
    planSubscriptionList(planId: $planId) {
      id
      created
      cron
      customerId
      gateway
      modified
      metadata {
        profileIds
        addressId
        userId
      }
      orgId
      planId
      token
      status
      startDate
    }
  }
`;
export const CUSTOMER_SUBSCRIPTION_LIST = gql`
  query customerSubscriptionList($custId: String!, $limit: Int, $nextToken: String) {
    customerSubscriptionList(custId: $custId, limit: $limit, nextToken: $nextToken) {
      total
      items {
        created
        planId
        modified
        gateway
        cron
        id
        orgId
        startDate
        status
        token
        customerId
      }
    }
  }
`;
export const CUSTOMER_SUBSCRIPTION = gql`
  query customerSubscription($custId: String, $orgId: String!) {
    customerSubscription(custId: $custId, orgId: $orgId) {
      id
      created
      cron
      customerId
      gateway
      modified
      orgId
      planId
      startDate
      status
      token
    }
  }
`;
export const GET_PLAN = gql`
  query getPlan($orgId: String!, $plnId: String!) {
    getPlan(orgId: $orgId, plnId: $plnId) {
      status
      orgId
      name
      modified
      interval
      id
      currency
      created
      amount
      gateway
    }
  }
`;
export const GET_CUSTOMER = gql`
  query getCustomer($orgId: String!, $custId: String!) {
    getCustomer(orgId: $orgId, custId: $custId) {
      lName
      created
      email
      fName
      id
      modified
      orgId
      ref
      status
    }
  }
`;
export const GET_SUBSCRIPTION = gql`
  query getSubscription($orgId: String!, $subId: String!) {
    getSubscription(orgId: $orgId, subId: $subId) {
      id
      cron
      created
      customerId
      gateway
      card {
        bank
        brand
        bin
        cardType
        expMonth
        country
        last4
        expYear
      }
      modified
      orgId
      planId
      startDate
      status
      token
      metadata {
        addressId
        profileIds
        userId
      }
    }
  }
`;
export const GET_SUBSCRIPTIONSTATUSCOUNT = gql`
  query subscriptionStatusCount($orgId: String!, $c: String!) {
    subscriptionStatusCount(c: $c, orgId: $orgId) {
      total
      items {
        doc_count
        key
      }
    }
  }
`;
export const GET_CHARGE = gql`
  query getCharge($subId: String!, $chgId: String!) {
    getCharge(subId: $subId, chgId: $chgId) {
      ref
      id
      subscriptionId
      created
      modified
      attempt
      date
      amount
      status
      error {
        description
        message
        name
      }
    }
  }
`;
export const GET_DETAILED_SUBSCRIPTION = gql`
  query getDetailedSubscription($orgId: String!, $subId: String!, $nextToken: String, $limit: Int) {
    getDetailedSubscription(orgId: $orgId, subId: $subId, nextToken: $nextToken, limit: $limit) {
      id
      customer {
        email
        lName
        fName
      }
      card {
        bank
        bin
        cardType
        brand
        country
        expMonth
        expYear
        last4
      }
      created
      cron
      customerId
      chargeList(limit: $limit, nextToken: $nextToken) {
        limit
        nextToken
        items {
          amount
          attempt
          subscriptionId
          status
          ref
          orgId
          modified
          id
          date
          customerId
          created
        }
      }
      startDate
      gateway
      modified
      orgId
      metadata
      planId
      plan {
        amount
        currency
        name
      }
      status
    }
  }
`;
export const GET_CHART = gql`
  query getChart($orgId: String!, $type: String!, $interval: String) {
    getChart(orgId: $orgId, type: $type, interval: $interval) {
      results
    }
  }
`;
export const GET_USERS_LIST = gql`
  query getUsersList($input: UserStatusListInput) {
    getUsersList(input: $input) {
      users {
        id
        created
        modified
        status
        fName
        lName
        email
        scopes
      }
      invites {
        id
        created
        modified
        status
        email
      }
      usersNextToken
      invitesNextToken
    }
  }
`;

export const GET_SETTINGS = gql`
  query getSettings($orgId: String!) {
    getSettings(orgId: $orgId) {
      status
      taxNumber
      type
      access {
        scopes
        sourceIps
        tokenIds
      }
      brand {
        color
        logoPath
      }
      gateways {
        Mock {
          secureToken
        }
        Stripe {
          secureApiKey
          publishableKey
        }
        Paystack {
          secureApiKey
        }
        Payfast {
          secureApiKey
          merchantId
          merchantKey
        }
      }
      name
      modified
      id
      notifications {
        actions
        emails
      }
      webhook {
        url
      }
      address {
        state
        postalCode
        line2
        line1
        country
        city
      }
    }
  }
`;

export const GET_PAYMENT_LINK = gql`
  query getPaymentLink($orgId: String!, $planId: String!) {
    getPaymentLink(orgId: $orgId, planId: $planId) {
      link
      error {
        message
        description
      }
    }
  }
`;
