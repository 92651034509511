import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { find } from 'lodash';
import { useAuthState } from './AuthContext';
import { Org, StringStringMap } from '../types';
import { ORGANISATION_CREATE } from '../graphql/mutations';

export type OrganisationCreateParams = Pick<Org, 'name' | 'type'> & {
  gateway: string;
  gatewayDetails: StringStringMap;
};

export type OrgStateContextType = {
  isLoading: boolean;
  organisations: Org[];
  currentOrganisation: Org;
  isAuthorisedForCurrentOrganisation: boolean;
};

export type OrgDispatchContextType = {
  // eslint-disable-next-line no-unused-vars
  dispatch: (action: any) => void;
  // eslint-disable-next-line no-unused-vars
  setOrganisation: (organisation: Org, route?: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setOrganisationById: (orgId: string, route?: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  createOrganisation: (org: OrganisationCreateParams) => Promise<Org>;
};

type OrgContextProps = {
  children: React.ReactNode;
};
export const OrgStateContext = createContext({} as OrgStateContextType);
export const OrgDispatchContext = createContext({} as OrgDispatchContextType);

const reducer: ImmerReducer<OrgStateContextType, any> = (draft, action) => {
  switch (action.type) {
    case 'clearSession': {
      draft.isLoading = true;
      draft.organisations = [];
      draft.currentOrganisation = null;
      draft.isAuthorisedForCurrentOrganisation = false;

      localStorage.removeItem('org');
      break;
    }
    case 'init': {
      draft.currentOrganisation = action.organisation;
      draft.organisations = action.organisations;
      draft.isAuthorisedForCurrentOrganisation = true;
      draft.isLoading = false;
      break;
    }
    case 'setOrganisation': {
      draft.currentOrganisation = action.organisation;
      draft.isAuthorisedForCurrentOrganisation = true;
      draft.isLoading = false;
      break;
    }
    case 'setOrganisationById': {
      const currentOrg = draft.organisations.find((o) => o.id === action.orgId);
      draft.currentOrganisation = currentOrg;
      draft.isAuthorisedForCurrentOrganisation = !!currentOrg;
      draft.isLoading = false;
      break;
    }
    case 'createOrganisation': {
      draft.currentOrganisation = action.organisation;
      draft.organisations = [...draft.organisations, action.organisation];
      draft.isAuthorisedForCurrentOrganisation = true;
      draft.isLoading = false;
      break;
    }
    default: {
      throw new Error(`Unhandled dispatch: ${action.type}`);
    }
  }
};

const initialState = {
  isLoading: true,
  organisations: [],
  currentOrganisation: undefined,
  isAuthorisedForCurrentOrganisation: false
};

export function OrgProvider({ children }: OrgContextProps): any {
  const router = useRouter();
  const [state, dispatch] = useImmerReducer<any, any>(reducer, initialState);
  const { currentOrganisation } = state;
  const { user, isAuthenticated, isLoading } = useAuthState();
  const [createOrganisationQuery] = useMutation(ORGANISATION_CREATE);

  const setOrganisation = useCallback(
    async (organisation: Org, route?: boolean): Promise<void> => {
      dispatch({ type: 'setOrganisation', organisation });

      localStorage.setItem('org', organisation.id);

      if (route) {
        router.push(`/dashboard/${organisation.id}/`);
      }
    },
    [dispatch]
  );

  const setOrganisationById = useCallback(
    async (orgId: string, route?: boolean): Promise<void> => {
      dispatch({ type: 'setOrganisationById', orgId });
      localStorage.setItem('org', orgId);

      if (route) {
        router.push(`/dashboard/${orgId}/`);
      }
    },
    [dispatch]
  );

  const createOrganisation = useCallback(
    async (org: OrganisationCreateParams): Promise<Org> => {
      const params = {
        name: org.name,
        type: org.type,
        gateway: org.gateway,
        gatewayDetails: org.gatewayDetails || {}
      };
      const response = await createOrganisationQuery({ variables: params });

      if (response.data.createOrganisation) {
        const { id } = response.data.createOrganisation;
        const standardisedId = id.indexOf('org_') === 0 ? id : `org_${id}`;

        dispatch({
          type: 'createOrganisation',
          organisation: { ...params, ...response.data.createOrganisation, id: standardisedId }
        });
        localStorage.setItem('org', `org_${id}`);

        return { ...params, ...response.data.createOrganisation, id: standardisedId };
      }

      return response.data.createOrganisation;
    },
    [dispatch]
  );

  const updateUrl = (orgId) => {
    router.push({
      pathname: `/dashboard/${orgId}/`
    });
  };

  // Organisations init
  useEffect(() => {
    if (!isAuthenticated || isLoading) {
      return;
    }

    // Set default org from previous session is available.
    let defaultOrg = user?.organisations?.[0] as unknown as Org;
    const prevOrg = localStorage.getItem('org');
    const path = router.asPath;
    const isDashboard = path.indexOf('dashboard') >= 0;
    const urlOrg = path.split('/').length > 1 ? path.split('/')[2] : '';

    // Load previous org
    if (prevOrg) {
      // Ensure there is a match on current users list
      const matchedOrg = find(user?.organisations, { id: prevOrg });

      if (matchedOrg) {
        defaultOrg = matchedOrg as unknown as Org;
      }
    }

    // Load org from URL path
    if (urlOrg) {
      // Ensure there is a match on current users list
      const matchedOrg = find(user?.organisations, { id: urlOrg });

      if (matchedOrg) {
        defaultOrg = matchedOrg as unknown as Org;
      }
    }

    // Set default or from users list
    dispatch({ type: 'init', organisation: defaultOrg, organisations: user?.organisations || [] });
    localStorage.setItem('org', defaultOrg?.id);

    // Route to default org

    if (isDashboard && path === '/dashboard/' && defaultOrg) {
      // Add log for default org
      const orgId = defaultOrg.id;
      if (orgId) {
        updateUrl(orgId);
        return;
      }
    }

    // Load org from path
    if (currentOrganisation && urlOrg !== defaultOrg.id) {
      setOrganisationById(urlOrg || defaultOrg.id);
    }
  }, [user?.organisations, isAuthenticated, router.asPath, router.query.location]);

  const dispatchContextValue = useMemo(
    () => ({
      dispatch,
      setOrganisation,
      setOrganisationById,
      createOrganisation
    }),
    [dispatch, setOrganisation, setOrganisationById, createOrganisation]
  );

  return (
    <OrgDispatchContext.Provider value={dispatchContextValue}>
      <OrgStateContext.Provider value={state}>{children}</OrgStateContext.Provider>
    </OrgDispatchContext.Provider>
  );
}

export const useOrgState = () => {
  const c = useContext(OrgStateContext);
  if (!c) throw new Error('Cannot use useOrgState when not under the CustomerPortalProvider');
  return c;
};

export const useOrgDispatch = () => {
  const c = useContext(OrgDispatchContext);
  if (!c) throw new Error('Cannot use useOrgDispatch when not under the CustomerPortalProvider');
  return c;
};
