import { extendTheme } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme';
import '@fontsource/inter/variable.css';

const theme: Record<string, any> = extendTheme(proTheme, {
  colors: {
    // @ts-ignore
    ...proTheme.colors,
    brand: {
      '50': '#f9f6fa',
      '100': '#e8ddea',
      '200': '#d3bfd9',
      '300': '#bb9bc3',
      '400': '#ad87b7',
      '500': '#9b6ca7',
      '600': '#8b5599',
      '700': '#773d86',
      '800': '#653372',
      '900': '#492552'
    },
    error: {
      '50': '#fff5f7',
      '100': '#ffd6df',
      '200': '#ffb0c1',
      '300': '#ff7c98',
      '400': '#f76080',
      '500': '#d1516c',
      '600': '#b0445b',
      '700': '#8e3749',
      '800': '#782f3e',
      '900': '#57222d'
    },
    'gray.700': '#353c3f'
  },
  styles: {
    global: {
      'html, body': { bgColor: 'bg-default' },
      'input:focus, select:focus': { borderColor: 'brand.300' }
    }
  },
  components: {
    Button: {
      variants: {
        solid: () => ({
          bgColor: 'brand.500'
        })
      }
    },
    Input: {
      baseStyle: {
        field: {
          backgroundColor: 'bg-input!important',
          _focus: {
            borderColor: 'brand.300!important'
          }
        }
      }
    },
    NumberInput: {
      baseStyle: {
        field: {
          backgroundColor: 'bg-input!important'
        }
      }
    },
    Modal: {
      baseStyle: {
        dialog: {
          bg: 'bg-modal'
        }
      }
    },
    Select: {
      baseStyle: {
        field: {
          // borderColor: 'brand.600!important',
          backgroundColor: 'bg-input',
          _focus: {
            borderColor: 'brand.300!important'
          }
        }
      }
    },
    Table: {
      baseStyle: {
        table: {
          bg: 'inherit'
        },
        thead: {
          th: {
            color: 'inherit',
            bg: 'inherit',
            fontWeight: 'semibold',
            borderTop: 'none',
            borderColor: 'subtle-border'
          }
        },
        tbody: {
          td: {
            borderColor: 'subtle-border'
          }
        }
      },
      variants: {
        simple: () => ({
          th: {
            color: 'black',
            bg: 'inherit'
          }
        })
      }
    },
    Popover: {
      baseStyle: {
        arrow: {
          bgColor: 'red'
        },
        content: {
          bgColor: 'bg-brand',
          borderColor: 'bg-brand',
          borderWidth: '0',
          _focus: { outline: 'none', borderColor: 'brand.100' },
          _focusVisible: { boxShadow: 'md', borderColor: 'brand.100' }
        }
      }
    }
  },
  semanticTokens: {
    colors: {
      'bg-default': {
        default: 'gray.200',
        _dark: 'gray.800'
      },
      'bg-alt': {
        default: 'gray.50',
        _dark: 'black'
      },
      'bg-darker': {
        default: 'gray.400',
        _dark: 'gray.700'
      },
      'bg-step': {
        default: 'gray.300',
        _dark: 'gray.800'
      },
      'bg-brand': {
        default: 'brand.400',
        _dark: 'brand.700'
      },
      'bg-secondary': {
        default: 'cyan.200',
        _dark: 'cyan.600'
      },
      'bg-default-inverted': {
        default: 'gray.700',
        _dark: 'gray.200'
      },
      'bg-navbar': {
        default: 'gray.100',
        _dark: 'gray.600'
      },
      'bg-surface': {
        default: 'whiteAlpha.500',
        _dark: 'blackAlpha.400'
      },
      accent: {
        default: 'brand.600',
        _dark: 'brand.500'
      },
      'subtle-border': {
        default: 'blackAlpha.100',
        _dark: 'whiteAlpha.100'
      },
      'bg-input': {
        default: 'white',
        _dark: 'gray.900'
      },
      'bg-modal': {
        default: 'white',
        _dark: 'gray.800'
      }
    }
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: true
  }
});

export default theme;
