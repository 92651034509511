import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import '../styles/globals.css';
import '../styles/animations.css';
import type { AppProps } from 'next/app';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { setContext } from '@apollo/client/link/context';
import { DefaultSeo } from 'next-seo';
import theme from '../libs/theme';
import { AuthProvider } from '../contexts/AuthContext';
import './demo/stripe/stripe.css';
import { PirschAnalyticsProvider } from '../contexts/PirschAnalyticsContext';
import 'easymde/dist/easymde.min.css';
import { OrgProvider } from '../contexts/OrgContext';

/* AWS Amplify and Appsync */
const APPSYNC_URL = process.env.NEXT_PUBLIC_APP_SYNC_API_URL;
const APPSYNC_KEY = process.env.NEXT_PUBLIC_APP_SYNC_API_KEY as string;
const httpLink = createHttpLink({
  uri: APPSYNC_URL
});
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authHeaders: { [header: string]: string } = {};
  await Auth.currentSession()
    .then((session) => {
      authHeaders.authorization = session.getIdToken().getJwtToken();
    })
    .catch(() => {
      // console.error('Auth session token error', err);
      authHeaders['x-api-key'] = APPSYNC_KEY;
    });

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...authHeaders
    }
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const config = {
  Auth: {
    region: process.env.NEXT_PUBLIC_REGION,
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID
  },
  aws_project_region: process.env.NEXT_PUBLIC_REGION,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.NEXT_PUBLIC_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
  oauth: {},
  aws_user_files_s3_bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
  aws_user_files_s3_bucket_region: process.env.NEXT_PUBLIC_REGION
};

Amplify.configure(config);
Auth.configure(config);

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={theme}>
      <DefaultSeo
        dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_STAGE !== 'prod'}
        dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_STAGE !== 'prod'}
        openGraph={{
          type: 'website',
          locale: 'en_GB',
          url: 'https://cronpay.io/',
          site_name: 'CronPay',
          images: [
            {
              url: 'https://cronpay.io/images/card.webp',
              width: 1068,
              height: 534,
              alt: 'CronPay'
            }
          ]
        }}
        twitter={{
          handle: '@cronpay_io',
          site: '@cronpay_io',
          cardType: 'summary_large_image'
        }}
        additionalLinkTags={[
          {
            rel: 'manifest',
            href: '/manifest-light.json'
          },
          {
            rel: 'shortcut icon',
            href: '/images/icons/icon-192x192.png'
          },
          {
            rel: 'apple-touch-icon',
            href: '/images/icons/icon-192x192-light.png'
          },
          {
            rel: 'apple-touch-startup-image',
            href: '/images/icons/icon-192x192.png'
          }
        ]}
        additionalMetaTags={[
          {
            name: 'mobile-web-app-capable',
            content: 'yes'
          },
          {
            name: 'apple-mobile-web-app-capable',
            content: 'yes'
          },
          {
            name: 'application-name',
            content: 'CronPay'
          },
          {
            name: 'apple-mobile-web-app-title',
            content: 'CronPay'
          },
          {
            name: 'theme-color',
            content: '#E2E8F0'
          },
          {
            name: 'msapplication-navbutton-color',
            content: '#E2E8F0'
          },
          {
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black-translucent'
          },
          {
            name: 'msapplication-starturl',
            content: '/dashboard/'
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
          },
          {
            name: 'msapplication-TileColor',
            content: '#e2e8f0'
          }
        ]}
      />
      <PirschAnalyticsProvider>
        <ApolloProvider client={client as any}>
          <AuthProvider>
            <OrgProvider>
              {/* @ts-ignore */}
              <Component {...pageProps} />
            </OrgProvider>
          </AuthProvider>
        </ApolloProvider>
      </PirschAnalyticsProvider>
    </ChakraProvider>
  );
}
