import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Reducer, useImmerReducer } from 'use-immer';
import { useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { USER } from '../graphql/queries';

export enum AuthStatus {
  /* eslint-disable no-unused-vars */
  LOADING,
  ANONYMOUS,
  AUTHENTICATED,
  PENDING_SIGN_IN,
  PENDING_SIGN_UP,
  PENDING_SIGN_OUT
}

export type Organisation = {
  access: string;
  amount: string;
  id: string;
  modified: string;
  name: string;
  status: string;
  permission: string;
};

export type AuthUserType = {
  id: string;
  email: string;
  fName?: string;
  lName?: string;
  organisations?: Organisation[];
};

export type AuthStateContextType = {
  isLoading: boolean;
  isAuthenticated: boolean;
  idToken: string | undefined;
  sessionStatus: AuthStatus;
  user: AuthUserType | undefined | null;
  organisations: Organisation[];
  isSettingCurrentOrganisation: boolean;
  isAuthorisedForCurrentOrganisation: boolean;
};

export type AuthDispatchContextType = {
  dispatch: (action: any) => void;
  signOut: () => Promise<void>;
};

type AuthContextProps = {
  children: React.ReactNode;
};
const sanitizeUser = (data: any): AuthUserType | null => {
  if (!data) {
    return null;
  }

  // cognito attributes
  return {
    id: data.attributes && (data.attributes.sub as string),
    email: data.attributes && data.attributes.email,
    fName: data.attributes && data.attributes.name,
    lName: data.attributes && data.attributes.family_name
  };
};
export const AuthStateContext = createContext({} as AuthStateContextType);
export const AuthDispatchContext = createContext({} as AuthDispatchContextType);

const authReducer: Reducer<AuthStateContextType> = (draft, action) => {
  switch (action.type) {
    case 'clearSession': {
      draft.user = undefined;
      draft.idToken = undefined;
      draft.isAuthenticated = false;
      draft.isLoading = true;
      draft.sessionStatus = AuthStatus.ANONYMOUS;
      draft.isSettingCurrentOrganisation = false;
      draft.organisations = []; // To pass to Org Context
      break;
    }
    case 'anonymousSession': {
      draft.user = undefined;
      draft.idToken = undefined;
      draft.isAuthenticated = false;
      draft.isLoading = false;
      draft.sessionStatus = AuthStatus.ANONYMOUS;
      break;
    }
    case 'signingIn': {
      draft.sessionStatus = AuthStatus.PENDING_SIGN_IN;
      break;
    }
    case 'signedIn': {
      draft.user = sanitizeUser(action.user);
      draft.idToken = action.user?.signInUserSession?.idToken?.jwtToken;
      draft.isAuthenticated = true;
      // draft.isLoading = false;
      draft.sessionStatus = AuthStatus.AUTHENTICATED;
      break;
    }
    case 'signingUp': {
      draft.sessionStatus = AuthStatus.PENDING_SIGN_UP;
      break;
    }
    case 'signingOut': {
      draft.sessionStatus = AuthStatus.PENDING_SIGN_OUT;
      draft.organisations = [];
      break;
    }
    case 'signedOut': {
      draft.user = undefined;
      draft.idToken = undefined;
      draft.isAuthenticated = false;
      draft.isLoading = false;
      draft.sessionStatus = AuthStatus.ANONYMOUS;
      break;
    }
    case 'setUserDetail': {
      draft.user.organisations = action.organisations || [];
      draft.isLoading = false;
      if (action.organisations) {
        draft.organisations = action.organisations;
        // draft.currentOrganisation =
        //   action.organisations.find((o) => o.id === action.selectedOrg) || action.organisations[0];
        // draft.isSettingCurrentOrganisation = false;
      }
      break;
    }
    default: {
      throw new Error(`Unhandled dispatch: ${action.type}`);
    }
  }
};

const initialState = {
  user: undefined,
  idToken: undefined,
  isAuthenticated: false,
  isLoading: true,
  sessionStatus: AuthStatus.LOADING,
  organisations: [],
  currentOrganisation: undefined,
  isSettingCurrentOrganisation: true,
  isAuthorisedForCurrentOrganisation: false
};

export function AuthProvider({ children }: AuthContextProps): any {
  const [state, dispatch] = useImmerReducer<any, any>(authReducer, initialState);
  const { user, isAuthenticated } = state;
  const router = useRouter();
  // TODO: add user wit destination after cognito initialise
  const [getUser, { data: getUserData, called: getUserCalled, loading: getUserLoading }] = useLazyQuery(USER, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const signOut = useCallback(async (): Promise<void> => {
    dispatch({ type: 'signingOut' });
    try {
      await Auth.signOut();
      // remove org id
      localStorage.removeItem('org');

      // Clear Sentry user data
      dispatch({ type: 'signedOut' });
    } catch (err) {
      console.error('AuthProvider:signOut:error', err);
    }
  }, [dispatch]);

  // Check auth status
  const checkAuthStatus = useCallback(async () => {
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        getUser();
        dispatch({ type: 'signedIn', user: currentUser });
      })
      .catch(() => dispatch({ type: 'anonymousSession' }));
  }, [dispatch]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  // Amplify Hub handling
  useEffect(() => {
    const hubHandler = async ({ payload }: any) => {
      if (payload.event === 'signIn') {
        // Add missing attributes for forced password resets.
        const tempUser = payload.data || (await Auth.currentUserInfo());
        dispatch({ type: 'signedIn', user: tempUser });
      }
      if (payload.event === 'signOut') {
        dispatch({ type: 'clearSession' });
      }
    };

    Hub.listen('auth', hubHandler);

    return () => {
      Hub.remove('auth', hubHandler);
    };
  }, [dispatch]);

  // Route guard
  useEffect(() => {
    // await authentication
    if (state.isLoading) {
      return;
    }

    const path = router.asPath;

    // Handle not signed in
    // is public page

    // Signed out
    if (!isAuthenticated && !state.isLoading) {
      // on signed-out
      if (path.match(/(^\/sign-out)/)) {
        router.push({ pathname: '/' });
        return;
      }

      // on private page
      if (path.match(/^\/(api|dashboard|demo|users)\//)) {
        router.push({ pathname: '/sign-in/' });
      }
    }
  }, [router.asPath, router.query.location, state.isLoading, state.isAuthenticated]);

  useEffect(() => {
    if (!getUserCalled && isAuthenticated) {
      getUser();
      return;
    }

    if (getUserLoading) {
      return;
    }

    // Logged in with orgs
    if (getUserData?.getUser && isAuthenticated && getUserData.getUser?.organisations !== null) {
      dispatch({
        type: 'setUserDetail',
        ...getUserData.getUser
      });
    }

    // Logged in without any orgs yet
    if (getUserData?.getUser && isAuthenticated && getUserData.getUser?.organisations === null) {
      // const orgId = getUserData.getUser?.organisations[0]?.id;

      dispatch({
        type: 'setUserDetail',
        ...getUserData.getUser
      });
    }
  }, [getUserLoading, getUserData, dispatch, isAuthenticated, getUserLoading, user?.email, user?.id]);

  const dispatchContextValue = useMemo(
    () => ({
      signOut,
      dispatch
    }),
    [signOut, dispatch]
  );

  return (
    <AuthDispatchContext.Provider value={dispatchContextValue}>
      <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
}

export const useAuthState = () => {
  const c = useContext(AuthStateContext);
  if (!c) throw new Error('Cannot use useAuthState when not under the AuthProvider');
  return c;
};

export const useAuthDispatch = () => {
  const c = useContext(AuthDispatchContext);
  if (!c) throw new Error('Cannot use useAuthDispatch when not under the AuthProvider');
  return c;
};
